import './bottomNavigation.scss';
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core';
import Paper from '@mui/material/Paper';
import {useState} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Person from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const MobileNavigation = () => {
    const pathname = window.location.pathname;
    const [selected, setSelected] = useState(pathname);
    const navigate = useNavigate();
    const user = useSelector(state => state.userStore.user);

    const showLabel = !!(user && user.isAdmin);

    return (
        <div>
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation
                    value={selected}
                    onChange={(value, newValue) => {
                        setSelected(newValue);
                    }}
                    style={{width: '100%'}}
                    showLabels={showLabel}
                >
                    <BottomNavigationAction
                        label='Bosh sahifa'
                        icon={<HomeIcon/>}
                        to=''
                        value='/'
                        onClick={() => navigate('/')}
                    />
                    <BottomNavigationAction
                        label='Narxlar'
                        onClick={() => navigate('/narxlar')}
                        to='/narxlar'
                        value='/narxlar'
                        icon={<InfoIcon/>}
                    />
                    <BottomNavigationAction label='Izlash'
                                            to='/saved'
                                            value='/saved'
                                            onClick={() => navigate('/saved')}
                                            icon={<FavoriteIcon/>}/>
                    {user && user.isAdmin &&
                        <BottomNavigationAction label='Admin'
                                                to='/admin'
                                                value='/admin'
                                                onClick={() => {
                                                    window.location.href = `${process.env.REACT_APP_API_URL}/admin/dashboard?showNews=false`;
                                                }}
                                                icon={<AdminPanelSettingsIcon/>}/>
                    }

                    <BottomNavigationAction label='Profil'
                                            to='/profil'
                                            value='/profil'
                                            onClick={() => navigate('/profil')}
                                            icon={<Person/>}/>
                </BottomNavigation>
            </Paper>
        </div>
    );
};

export default MobileNavigation;
